<template>
  <section class="section">
    <h2 class="service subtitle">Сводный отчет</h2>

    <form @submit.prevent="getReportData()">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field :label="$ml.get('date_from')">
              <b-datepicker
                icon-pack="far"
                :month-names="getMonthNames"
                :day-names="getDayNames"
                v-model="formData.date_from"
                placeholder="Нажмите для выбора даты..."
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="field">
            <b-field :label="$ml.get('date_to')">
              <b-datepicker
                icon-pack="far"
                :month-names="getMonthNames"
                :day-names="getDayNames"
                v-model="formData.date_to"
                placeholder="Нажмите для выбора даты..."
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field label="Форма статистики  ">
              <b-select v-model="formData.report_type" id="report_type">
                <option
                  v-for="reportType in reportTypes"
                  :value="reportType.value"
                  :key="reportType.value"
                >
                  {{ reportType.text }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>

      <div class="field is-horizontal is-pulled-right">
        <div class="field-body">
          <button class="button is-success" type="submit">
            Загрузить отчет
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Api from "../../services/api/api-resource/api-instance";
import { DatepickerMixin } from "../../mixins/datepicker-mixin";
import moment from "moment";

export default {
  name: "ReportsIndex",
  mixins: [DatepickerMixin],
  data() {
    return {
      inProgress: false,
      formData: {
        report_type: "KppkForm1",
        date_from: new Date(),
        date_to: new Date()
      },
      reportTypes: [
        {
          text:
            "1 форма - Сведения  о детях с ограниченными  возможностями в  развитии, состоящих на учете ПМПК",
          value: "KppkForm1"
        },
        {
          text:
            "2 форма - Сведения ПМПК о количестве детей   с ограниченными возможностями в развитии",
          value: "KppkForm2"
        },
        {
          text:
            "3 форма - Сведения ПМПК о количестве детей   с ограниченными возможностями в развитии",
          value: "KppkForm3"
        }
      ]
    };
  },
  methods: {
    getReportData() {
      Api.axios
        .get("/api/reports/sign-export", {
          params: {
            report_type: this.formData.report_type,
            date_from: moment(this.formData.date_from).format("YYYY-MM-DD"),
            date_to: moment(this.formData.date_to).format("YYYY-MM-DD")
          }
        })
        .then(response => {
          window.open(response.data, "_blank");
          // console.log(response.data);
        });
      // .catch(() => {
      // });
    }
  }
};
</script>
